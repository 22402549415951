var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-flow-item",attrs:{"data-item":_vm.item.id},on:{"mouseenter":function($event){return _vm.setGraphActiveItem(_vm.item.id)},"mouseleave":function($event){return _vm.setGraphActiveItem(null)}}},[_c('div',{staticClass:"top-labels text-muted d-flex justify-content-between"},[(_vm.item.date)?_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("displayDate")(_vm.item.date)))]):_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.$t('commons.unknownDate')))])]),_c('div',{staticClass:"activity-card",class:{
      active: _vm.isTreeActive,
      'missing-activity': _vm.item.isMissing,
      counter: _vm.item.showCounter,
      main: _vm.item.showMain,
    },on:{"click":function($event){return _vm.setSelectedItem(_vm.item)}}},[_c('div',[_vm._t("default",null,{"item":_vm.item})],2),_c('hr'),_c('div',{staticClass:"activity-card-footer"},[(!_vm.item.isMissing && _vm.item.document)?[_c('div',{staticClass:"document-link"},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDocumentModal(_vm.item.document.id)}}},[_vm._v(" "+_vm._s(_vm.$t(`document.exports.schema.type.shortName.${_vm.item.document.type}`))+" "+_vm._s(_vm.item.document.documentNumber)+" ")])]),_c('div',{staticClass:"tags"},[(_vm.hasDifferences)?_c('Tag',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('commons.differenceWithSupplier'))+" ")]):_vm._e(),(_vm.hasImbalances)?_c('Tag',{attrs:{"type":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('commons.imbalanced'))+" ")]):_vm._e()],1)]:_vm._e(),(_vm.item.isMissing)?[_c('div',{staticClass:"missing-footer"},[_c('el-tooltip',{attrs:{"content":_vm.$t('eventMap.missingDoc.tooltip'),"placement":"top","effect":"light"}},[_c('NoticeIcon')],1),_vm._v(" "+_vm._s(_vm.$t('commons.document'))+" "+_vm._s(_vm.item.document.documentNumber)+" ")],1),_c('Tag',{attrs:{"size":"medium","type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('commons.unconfirmed'))+" ")])]:_vm._e()],2)]),(_vm.shouldDisplayMatchLink)?_c('div',{staticClass:"credit-matching-link",on:{"click":function($event){return _vm.openCreditMatchModal(_vm.item)}}},[_c('LinkIcon',{attrs:{"height":"16px","width":"16px"}})],1):_vm._e(),(_vm.hasImbalances)?_c('div',{staticClass:"imbalances-left-row"},[_vm._v(" "+_vm._s(_vm.balanceAmountText)+" ")]):_vm._e(),(_vm.isBilling)?_c('div',_vm._l((_vm.item.payload.related),function(billing){return _c('div',{key:billing.id,class:{
        'credit-row': true,
        counter: !_vm.item.showCounter,
        main: !_vm.item.showMain,
      },on:{"click":function($event){return _vm.openCreditMatchModal(_vm.item, billing)}}},[_c('div',{staticClass:"document-link"},[_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDocumentModal(billing.document.id)}}},[_vm._v(" "+_vm._s(_vm.$t(`document.exports.schema.type.shortName.${billing.document.type}`))+" "+_vm._s(billing.document.documentNumber)+" ")])]),_c('span',[_vm._v(_vm._s(`${_vm.$t('eventMapModal.billingCard.creditMatching.matched')} ${_vm.formatMoney(_vm.getMatchedAmount(billing))}`))])])}),0):_vm._e(),(_vm.viewDoc)?_c('DocumentModal',{attrs:{"visible":_vm.viewDoc,"document-id":_vm.documentId},on:{"update:visible":function($event){_vm.viewDoc=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }