<template>
  <div class="activity-flow-graph">
    <div class="graph-content" :style="{ minWidth: width }">
      <ActivityFlowLinks :links="links" />
      <ActivityFlowHeader :columns="headers" />
      <div class="month-splitter">
        <span class="date-badge">{{ formatDateMonth(month) }}</span>
      </div>
      <ActivityFlowColumns class="flow-columns" :columns="columns">
        <template #column-0-item="{ item: { payload: order, showCounter, showMain } }">
          <div v-if="order" class="order-box">
            <div>
              <div v-if="order.itemsCount.ordered">
                <div v-if="showCounter && showMain" class="dot turquoise" />
                {{ order.itemsCount.ordered }} {{ $t('commons.products') }}
              </div>
              <div v-if="order.itemsCount.returnNotice">
                <div v-if="showCounter && showMain" class="dot purple" />
                {{ (order.itemsCount.returnNotice * -1).toLocaleString($i18n.locale) }}
                {{ $t('commons.products') }}
              </div>
            </div>
            <div class="fw-bold">
              {{ formatMoneyShekels(order.amount) }}
            </div>
          </div>
        </template>
        <template #column-1-item="{ item: { payload: delivery, showCounter, showMain } }">
          <div v-if="delivery">
            <div v-if="delivery.products.filter(positiveQuantity).length">
              <div v-if="showCounter && showMain" class="dot turquoise" />
              {{ delivery.products.filter(positiveQuantity).length }} {{ $t('products') }}
            </div>
            <div v-if="delivery.products.filter(negativeQuantity).length">
              <div v-if="showCounter && showMain" class="dot purple" />
              {{ (delivery.products.filter(negativeQuantity).length * -1).toLocaleString($i18n.locale) }}
              {{ $t('commons.products') }}
            </div>
          </div>
        </template>
        <template #column-2-item="{ item: { payload: billing } }">
          <div v-if="billing" class="money-descriptor">
            <div class="font-color-gray">{{ $t('commons.net') }}</div>
            <div class="fw-bold">{{ formatMoney(billing.netAmount) }}</div>
          </div>
          <div class="money-descriptor">
            <div class="font-color-gray">{{ $t('commons.vat') }}</div>
            <div>{{ formatMoney(billing.taxAmount) }}</div>
          </div>
          <div class="money-descriptor">
            <div class="font-color-gray">{{ $t('commons.total') }}</div>
            <div>{{ formatMoney(billing.totalAmount) }}</div>
          </div>
        </template>
      </ActivityFlowColumns>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import ActivityFlowHeader from '@/modules/activity/components/ActivityFlowGraph/ActivityFlowHeader';
import ActivityFlowColumns from '@/modules/activity/components/ActivityFlowGraph/ActivityFlowColumns';
import ActivityFlowLinks from '@/modules/activity/components/ActivityFlowGraph/ActivityFlowLinks';
import { useCurrency } from '@/modules/core/compositions/money-currency';

const COLUMN_WIDTH = 304;

export default {
  name: 'ActivityFlowGraph',
  components: { ActivityFlowColumns, ActivityFlowHeader, ActivityFlowLinks },
  props: {
    columns: { type: Array, default: () => [] },
    month: { type: String, default: '' },
  },
  setup(props) {
    const { formatToCurrency, formatCentsToCurrency } = useCurrency();
    return {
      formatToCurrency,
      formatCentsToCurrency,
      headers: computed(() =>
        props.columns.map(({ type }, index) => ({
          type,
          index,
        }))
      ),
      width: computed(() => props.columns.length * COLUMN_WIDTH + 'px'),
      links: computed(() =>
        props.columns.reduce((allLinks, { items }) => {
          for (let item of items) {
            for (let to of item.linkedItems) {
              allLinks.push({ from: item.id, to });
            }
          }
          return allLinks;
        }, [])
      ),
    };
  },

  methods: {
    formatMoney(amount) {
      return this.formatCentsToCurrency(amount, { minimumFractionDigits: 0 });
    },
    formatMoneyShekels(amount) {
      return this.formatToCurrency(amount, { minimumFractionDigits: 0 });
    },
    formatDateMonth(ms) {
      return ms ? new Date(ms).toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' }) : '';
    },
    positiveQuantity({ quantity }) {
      return quantity > 0;
    },
    negativeQuantity({ quantity }) {
      return quantity < 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import '../../style/dimensions';

.month-splitter {
  $margin-vertical: 40px;
  margin: $margin-vertical 0px;
  top: calc(#{$activity-headers-height} + #{$margin-vertical});

  &::before {
    content: '';
    position: absolute;
    border-bottom: 1px dashed $informative;
    width: 100%;
    transform: translateY(10px);
  }

  .date-badge {
    background: #1f284d;
    color: $white;
    padding: 7px 8px 8px;
    position: absolute;
    border-radius: 6px;
    transform: translate(48px, -9px);

    [dir='rtl'] & {
      // item padding is 48px
      transform: translate(-48px, -7px);
    }
  }
}

.font-color-gray {
  color: $typography-secondary;
}

.activity-flow-graph {
  flex: 1;
  height: 100%;
  background-color: #fff;
  overflow: auto;
}

.graph-content {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.money-descriptor {
  display: flex;
  justify-content: space-between;
}

.order-box {
  display: flex;
  justify-content: space-between;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 36px;
  display: inline-block;
  vertical-align: middle;

  &.purple {
    background: $counter-action;
  }

  &.turquoise {
    background: $main-action;
  }
}
</style>
