import { render, staticRenderFns } from "./ActivityFlowHeader.vue?vue&type=template&id=54216a57&scoped=true"
import script from "./ActivityFlowHeader.vue?vue&type=script&lang=js"
export * from "./ActivityFlowHeader.vue?vue&type=script&lang=js"
import style0 from "./ActivityFlowHeader.vue?vue&type=style&index=0&id=54216a57&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54216a57",
  null
  
)

export default component.exports