<template>
  <div class="links" :style="{ width: width + 'px', height: height + 'px' }">
    <svg v-if="width" :width="width" :height="height">
      <path
        v-for="line in lines"
        :key="line.id"
        :d="line.d"
        stroke-linejoin="round"
        stroke-width="5"
        fill="none"
        :class="{ active: line.isActive }"
      />
    </svg>
  </div>
</template>

<script>
import { state, setGraphActiveTree } from '@/modules/activity/store/supplier-activity-store';

export default {
  name: 'ActivityFlowLinks',
  props: {
    links: { type: Array, default: () => [] },
  },
  data() {
    return {
      lines: [],
      shouldRender: false,
      width: 0,
      height: 0,
      resize: new ResizeObserver(this.paintWithDimension),
    };
  },
  computed: {
    linkedEls() {
      return this.links
        .map(({ from, to }) => {
          const fromEl = this.graphEl.querySelector(`[data-item="${from}"]`);
          const toEl = this.graphEl.querySelector(`[data-item="${to}"]`);

          if (!fromEl || !toEl) return;

          return { from: fromEl, fromId: from, to: toEl, toId: to };
        })
        .filter((linkedEl) => linkedEl);
    },
    graphEl() {
      return this.$el.offsetParent;
    },
    activeLines() {
      if (!state.graph.activeItem) {
        return [];
      }
      return [
        ...this.getActiveRecursive([state.graph.activeItem], 'from'),
        ...this.getActiveRecursive([state.graph.activeItem], 'to'),
      ];
    },
  },
  watch: {
    activeLines() {
      this.paint();
      const activeIds = this.activeLines.reduce((ids, { from, to }) => {
        ids[from] = true;
        ids[to] = true;
        return ids;
      }, {});
      setGraphActiveTree(Object.keys(activeIds));
    },
  },
  mounted() {
    this.graphEl.parentElement.addEventListener('scroll', this.paint);
    this.resize.observe(this.graphEl);
    this.paintWithDimension();
  },
  destroy() {
    this.graphEl.parentElement.removeEventListener('scroll', this.paint);
    this.resize.disconnect();
  },
  methods: {
    async paintWithDimension() {
      await this.setDimensions();
      this.paint();
    },
    async setDimensions() {
      this.width = this.height = 0;

      await this.$nextTick();

      this.width = this.graphEl.scrollWidth;
      this.height = this.graphEl.scrollHeight;
    },
    paint() {
      this.lines = this.linkedEls
        .map(({ from, to, fromId, toId }) => {
          const id = `FROM:${fromId}.TO:${toId}`;

          const geo = this.getGeometricElements(from, to);

          const fromX = geo.from.offset.left + geo.from.el.clientWidth;
          const fromY = geo.from.offset.top + geo.from.el.clientHeight / 2;

          const toX = geo.to.offset.left;
          const toY = geo.to.offset.top + geo.to.el.clientHeight / 2;

          const width = Math.abs((toX - fromX) / 2);

          const IS_STEP_UP = fromY > toY;

          const GAP_X = -12;
          const GAP_Y = IS_STEP_UP ? -Math.max((toY - fromY) / 2, GAP_X) : Math.max((fromY - toY) / 2, GAP_X);

          const isActive = !!this.activeLines.some((line) => fromId === line.from && toId === line.to);

          const obj = {
            start: `${fromX} ${fromY}`,
            halfWay: {
              x: fromX + width,
              y: fromY,
            },
            secondHalf: {
              x: fromX + width,
              y: toY,
            },
            end: `${toX} ${toY}`,
          };

          if (fromY === toY) {
            return {
              id,
              d: `M ${obj.start} L ${obj.end}`,
              isActive,
            };
          }

          return {
            id,
            d: [
              `M ${obj.start}`,
              `L ${obj.halfWay.x + GAP_X} ${obj.halfWay.y}`,
              `Q ${obj.halfWay.x} ${obj.halfWay.y}, ${obj.halfWay.x} ${obj.halfWay.y - GAP_Y}`,
              `L ${obj.secondHalf.x} ${obj.secondHalf.y + GAP_Y}`,
              `Q ${obj.secondHalf.x} ${obj.secondHalf.y}, ${obj.secondHalf.x - GAP_X} ${obj.secondHalf.y}`,
              `L ${obj.end}`,
            ].join(' '),
            isActive,
          };
        })
        .sort((a) => (a.isActive ? 1 : -1));
    },
    /**
     *
     * @param {HTMLElement} el
     */
    getItemOffset(el) {
      const offset = {
        top: el.offsetTop,
        left: el.offsetLeft,
      };

      let offsetParent = el.offsetParent;
      while (offsetParent && offsetParent !== this.graphEl) {
        offset.top += offsetParent.offsetTop;
        offset.left += offsetParent.offsetLeft;
        offsetParent = offsetParent.offsetParent;
      }

      return offset;
    },
    getActiveRecursive(ids = [], direction = 'from') {
      const oppositeDirection = direction === 'from' ? 'to' : 'from';
      const active = this.links.filter((link) => ids.includes(link[direction]));
      if (active.length) {
        return active.concat(
          this.getActiveRecursive(
            active.map((link) => link[oppositeDirection]),
            direction
          )
        );
      }
      return active;
    },
    getGeometricElements(fromEl, toEl) {
      const from = {
        el: fromEl,
        offset: this.getItemOffset(fromEl),
      };
      const to = {
        el: toEl,
        offset: this.getItemOffset(toEl),
      };
      const isFromBefore = from.offset.left < to.offset.left;
      if (isFromBefore) {
        return {
          from,
          to,
        };
      } else {
        return {
          from: to,
          to: from,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

@keyframes strokeIn {
  0% {
    stroke: #e5e8ea;
  }
  10% {
    stroke: #e5e8ea;
  }
  100% {
    stroke: #0f598b;
  }
}

.links {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

svg {
  width: 100%;
  height: 100%;

  path {
    stroke: #e5e8ea;

    &.active {
      stroke: #0f598b;
      animation: strokeIn ease-in-out 250ms;
    }

    &.error {
      stroke: red;
    }
  }
}
</style>
