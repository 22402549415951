<template>
  <div class="activity-flow-header">
    <div v-for="column in columns" :key="column.index" class="header-column">
      <div class="action-indicator main-action">
        {{ $t(`eventMap.activitiesHeaders.${column.type}.main`) }}
      </div>
      <div class="action-indicator counter-action">
        {{ $t(`eventMap.activitiesHeaders.${column.type}.counter`) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityFlowHeader',
  props: {
    columns: { type: Array, required: true },
  },
};
</script>

<style scoped lang="scss">
@import '../../style/dimensions';
@import '@/stylesheets/scss/global';

.activity-flow-header {
  display: flex;
  height: $activity-headers-height;
  flex-direction: row;
  border-bottom: 1px solid $outline;
  color: $typography-primary;
  background-color: $white;
  position: sticky;
  top: 0;
  z-index: 2;
}

.header-column {
  width: $activity-item-width + ($activity-connection-part-line-width * 2);
  padding: 16px $activity-connection-part-line-width;
  display: flex;
  flex-direction: column;
}
</style>
