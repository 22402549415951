<template>
  <div
    class="activity-flow-item"
    :data-item="item.id"
    @mouseenter="setGraphActiveItem(item.id)"
    @mouseleave="setGraphActiveItem(null)"
  >
    <div class="top-labels text-muted d-flex justify-content-between">
      <span v-if="item.date" class="date">{{ item.date | displayDate }}</span>
      <span v-else class="date">{{ $t('commons.unknownDate') }}</span>
    </div>
    <div
      class="activity-card"
      :class="{
        active: isTreeActive,
        'missing-activity': item.isMissing,
        counter: item.showCounter,
        main: item.showMain,
      }"
      @click="setSelectedItem(item)"
    >
      <div>
        <slot :item="item" />
      </div>
      <hr />
      <div class="activity-card-footer">
        <template v-if="!item.isMissing && item.document">
          <div class="document-link">
            <a href="" @click.prevent.stop="openDocumentModal(item.document.id)">
              {{ $t(`document.exports.schema.type.shortName.${item.document.type}`) }}
              {{ item.document.documentNumber }}
            </a>
          </div>
          <div class="tags">
            <Tag v-if="hasDifferences" type="warning">
              {{ $t('commons.differenceWithSupplier') }}
            </Tag>
            <Tag v-if="hasImbalances" type="danger">
              {{ $t('commons.imbalanced') }}
            </Tag>
          </div>
        </template>
        <template v-if="item.isMissing">
          <div class="missing-footer">
            <el-tooltip :content="$t('eventMap.missingDoc.tooltip')" placement="top" effect="light">
              <NoticeIcon />
            </el-tooltip>
            {{ $t('commons.document') }} {{ item.document.documentNumber }}
          </div>
          <Tag size="medium" type="warning">
            {{ $t('commons.unconfirmed') }}
          </Tag>
        </template>
      </div>
    </div>
    <div v-if="shouldDisplayMatchLink" class="credit-matching-link" @click="openCreditMatchModal(item)">
      <LinkIcon height="16px" width="16px" />
    </div>
    <div v-if="hasImbalances" class="imbalances-left-row">
      {{ balanceAmountText }}
    </div>
    <div v-if="isBilling">
      <div
        v-for="billing in item.payload.related"
        :key="billing.id"
        :class="{
          'credit-row': true,
          counter: !item.showCounter,
          main: !item.showMain,
        }"
        @click="openCreditMatchModal(item, billing)"
      >
        <div class="document-link">
          <a href="" @click.prevent.stop="openDocumentModal(billing.document.id)">
            {{ $t(`document.exports.schema.type.shortName.${billing.document.type}`) }}
            {{ billing.document.documentNumber }}
          </a>
        </div>
        <span>{{
          `${$t('eventMapModal.billingCard.creditMatching.matched')} ${formatMoney(getMatchedAmount(billing))}`
        }}</span>
      </div>
    </div>
    <DocumentModal v-if="viewDoc" :visible.sync="viewDoc" :document-id="documentId" />
  </div>
</template>

<script>
import moment from 'moment';
import { computed, ref } from 'vue';

import { useActivityState } from '@/modules/activity/store/activity-state';
import { setGraphActiveItem, setSelectedItem, state } from '@/modules/activity/store/supplier-activity-store';
import { DocumentModal } from '@/modules/documentModal';
import { Tag } from '@/modules/core';
import { NoticeIcon, LinkIcon } from '@/assets/icons';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  name: 'ActivityFlowItem',
  filters: {
    displayDate(date) {
      return moment(new Date(date)).format('DD/MM/YYYY');
    },
  },
  components: { LinkIcon, DocumentModal, Tag, NoticeIcon },
  props: {
    item: { type: Object, required: true },
  },
  setup(props) {
    const { selectedCredit, showCreditMatchModal } = useActivityState();
    const { formatCentsToCurrency } = useCurrency();

    const isBilling = computed(() => props.item?.type === 'billing');
    const imbalances = computed(() => props.item?.payload?.imbalances || []);

    const balance = computed(
      () =>
        (isBilling.value &&
          imbalances.value?.reduce((sum, imbalance) => {
            const billed = imbalance.billedAmounts?.reduce(
              (billedSum, billedAmount) => billedSum + billedAmount.amount,
              0
            );
            const ordered = imbalance.orderedAmounts?.reduce(
              (orderedSum, orderedAmount) => orderedSum + orderedAmount.amount,
              0
            );
            return sum + billed - ordered;
          }, 0)) ||
        0
    );

    return {
      formatCentsToCurrency,
      viewDoc: ref(false),
      setGraphActiveItem,
      selectedCredit,
      showCreditMatchModal,
      setSelectedItem: (item) => {
        if (item.isMissing) return;
        setSelectedItem(item);
      },
      isTreeActive: computed(() => {
        return state.graph.activeTree.includes(props.item.id);
      }),
      hasDifferences: computed(() => !!props.item?.payload?.diffs?.length),
      hasImbalances: computed(() => !!imbalances.value?.length),
      shouldDisplayMatchLink: computed(
        () => props.item?.type === 'billing' && (props.item?.payload?.related?.length || !!imbalances.value?.length)
      ),
      imbalances,
      balance,
      isBilling,
      getMatchedAmount(billing) {
        const creditBilling = billing.netAmount < 0 ? billing : props.item?.payload;
        const debitBilling = billing.netAmount >= 0 ? billing : props.item?.payload;

        return creditBilling.orderLinks
          .filter((orderLink) =>
            debitBilling.orderLinks.some((billingOrderLink) => billingOrderLink?.order?.id === orderLink?.order?.id)
          )
          .reduce((sum, orderLink) => sum + orderLink.amount, 0);
      },
    };
  },
  computed: {
    balanceAmountText() {
      const formattedBalance = this.formatMoney(this.balance);

      if (this.item.showMain) {
        if (this.balance > 0) {
          return `${this.$t('eventMapModal.billingCard.creditMatching.waitingForCreditMatch')} ${formattedBalance}`;
        }
        return `${this.$t('eventMapModal.billingCard.creditMatching.undercharged')} ${formattedBalance}`;
      }

      return `${this.$t('eventMapModal.billingCard.creditMatching.waitingForMatch')} ${formattedBalance}`;
    },
  },
  methods: {
    formatMoney(value) {
      return this.formatCentsToCurrency(value);
    },
    openCreditMatchModal(billing, relatedBilling) {
      // Related click
      // parent is credit and related is debit
      if (billing.showCounter && relatedBilling) {
        this.showCreditMatchModal = true;
      }
      // parent is debit related is credit
      if (billing.showMain && relatedBilling) {
        this.selectedCredit = { parentId: billing.id, relatedId: relatedBilling.id };
      }

      // Parent click
      // parent is debit
      if (!relatedBilling && billing.showMain) {
        this.showCreditMatchModal = true;
      }

      // parent is credit
      if (!relatedBilling && billing.showCounter) {
        this.selectedCredit = { parentId: billing.id };
      }
    },
    openDocumentModal(id) {
      this.documentId = id;
      this.viewDoc = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import '../../style/dimensions';

.activity-flow-item {
  border-radius: 6px;
  margin: 10px 0;
  &.active,
  &:hover {
    transform: translateY(-3px);

    .imbalances-left-row,
    .credit-row {
      box-shadow: 0 12px 24px rgba(84, 90, 96, 0.14), 0 8px 4px rgba(0, 0, 0, 0.06);
    }

    .activity-card {
      box-shadow: 0 0 0 0 rgba(84, 90, 96, 0.14), 0 4px 4px rgba(0, 0, 0, 0.06);
    }
  }
}

$padding: 12px;
.activity-card {
  &::before,
  &::after {
    content: '';
    visibility: hidden;
    width: 4px;
    height: 23px;
    top: -10px;
    position: absolute;
    transform: rotate(90deg);
  }

  $horizontal-position: 10px;
  $left-direction-radius: 16px 0px 16px 0px;
  $right-direction-radius: 0px 16px 0px 16px;

  &.counter::before {
    visibility: visible;
    background: $counter-action;

    [dir='ltr'] & {
      right: $horizontal-position;
      border-radius: $left-direction-radius;
    }
    [dir='rtl'] & {
      left: $horizontal-position;
      border-radius: $right-direction-radius;
    }
  }
  &.main::after {
    visibility: visible;
    background: $main-action;

    [dir='ltr'] & {
      left: $horizontal-position;
      border-radius: $right-direction-radius;
    }

    [dir='rtl'] & {
      right: $horizontal-position;
      border-radius: $left-direction-radius;
    }
  }

  background: $white;
  width: $activity-item-width;
  min-height: 8rem;
  transition: all 150ms ease-in-out;
  box-shadow: 0 2px 15px rgba(62, 95, 128, 0.07), 0 2px 2px rgba(62, 95, 128, 0.1);
  border-radius: 6px;
  border: 1px solid $outline;
  padding: $padding;
  padding-bottom: 6px;
  display: grid;
  grid-template-rows: 2fr 12px 1fr;
  color: $typography-primary;
  transform: translate(0px); //fixes the before and after position when hover and not hover

  &.missing-activity {
    border: 2px $warning dashed;
  }

  hr {
    margin: auto 0;
    transform: translateX(-#{$padding});

    [dir='rtl'] & {
      transform: translateX($padding);
    }
    width: calc(100% + #{($padding * 2)});
    height: 1px;
  }

  .activity-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .document-link {
      margin-top: 0.25em;
      font-size: 0.8em;
    }

    .missing-footer {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
      font-weight: 400;
      font-size: 0.9em;
      color: $warning;
    }

    .tags {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;

      ::v-deep .tag-content {
        font-size: 0.8em;
      }
    }

    .amount-matched {
      font-size: 0.8em;
    }
  }
}

.top-labels {
  display: flex;
  align-items: center;

  .date {
    margin-bottom: 2px;
    font-weight: 300;
    font-size: 12px;
    line-height: 124%;
    padding: 0 5px;
  }
}

.credit-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: $white;
  font-size: 0.8em;
  border: 1px solid $outline;
  color: $typography-primary;
  width: $activity-item-width;
  padding: 6px 12px;
  margin-top: 0.5em;
  transform: translate(0px); //fixes the before and after position when hover and not hover

  &::before,
  &::after {
    content: '';
    visibility: hidden;
    width: 4px;
    height: 23px;
    top: -10px;
    position: absolute;
    transform: rotate(90deg);
  }

  $horizontal-position: 10px;
  $left-direction-radius: 16px 0px 16px 0px;
  $right-direction-radius: 0px 16px 0px 16px;

  &.counter::before {
    visibility: visible;
    background: $counter-action;

    [dir='ltr'] & {
      right: $horizontal-position;
      border-radius: $left-direction-radius;
    }
    [dir='rtl'] & {
      left: $horizontal-position;
      border-radius: $right-direction-radius;
    }
  }
  &.main::after {
    visibility: visible;
    background: $main-action;

    [dir='ltr'] & {
      left: $horizontal-position;
      border-radius: $right-direction-radius;
    }

    [dir='rtl'] & {
      right: $horizontal-position;
      border-radius: $left-direction-radius;
    }
  }
}

.credit-matching-link {
  display: flex;
  justify-content: center;
  align-items: center;
  background: lighten($primary, 5%);
  color: $white;
  border: 1px solid $primary;
  border-radius: 36px;
  padding: 2px;
  position: absolute;
  margin: 0 ($activity-item-width - 22px) / 2;
  transform: translateY(-10px);
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}

.imbalances-left-row {
  display: flex;
  align-items: center;
  color: $warning;
  padding: 6px 12px;
  border-radius: 6px;
  background: $white;
  margin-top: 0.5em;
  border: 1px solid $outline;
  width: $activity-item-width;
  font-size: 0.8em;
  box-shadow: 0 2px 15px rgba(62, 95, 128, 0.07), 0 2px 2px rgba(62, 95, 128, 0.1);

  svg {
    color: $warning;

    [dir='ltr'] & {
      margin-right: 4px;
    }

    [dir='rtl'] & {
      margin-left: 4px;
    }
  }
}
</style>
